<template>
  <div>
    <v-skeleton-loader class="wl-login-loader" type="avatar" />
    <h1 class="wl-login-loader-text">Logging in ...</h1>
  </div>
</template>

<script>
import auth from '../state/utils/auth'
import { store } from '@/state/store'

export default {
  async created() {
    await this.handleAuthentication()
    this.$router.push({ name: 'loginRouter' })
  },
  methods: {
    async handleAuthentication() {
      const authResponse = await auth.handleAuthentication().catch((e) => e)
      if (authResponse instanceof Error) {
        // alert

        return
      }
      const { user } = authResponse || {}
      store.dispatch('user/setCurrentUser', user)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
</style>
